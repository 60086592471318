import * as Styled from '../styles/ProductPage.style'
import React, {useContext, useEffect} from 'react'
import {document, window} from 'browser-monads'

import AppContext from '../utils/context/AppContext.jsx'

function ProductPage({pageContext}) {
  const id = pageContext.bigwaxId
  const titre = pageContext.titre
  const {products, buyButtonOptions} = useContext(AppContext)

  const currProduct = products.filter((product) => product.titre === titre)[0]

  useEffect(() => {
    var scriptURL =
      'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        console.log('ShopifyBuyInit')
        ShopifyBuyInit()
      } else {
        loadScript()
      }
    } else {
      loadScript()
    }
    function loadScript() {
      var script = document.createElement('script')
      script.async = true
      script.src = scriptURL
      ;(
        document.getElementsByTagName('head')[0] ||
        document.getElementsByTagName('body')[0]
      ).appendChild(script)
      script.onload = ShopifyBuyInit
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN
      })
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: id,
          node: document.getElementById('buy-button'),
          moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
          options: buyButtonOptions
        })
      })
    }
  }, [id, buyButtonOptions])

  return (
    <Styled.ProductPage
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 2}}
      background={titre.includes('GRAPHITE') ? 'graphite' : 'coton'}
    >
      {/* <motion.div
        className="productDescription"
        animate={{opacity: scrollY > windowHeight * 0.5 ? 0 : 0.8}}
        style={{pointerEvents: scrollY > windowHeight * 0.5 ? 'none' : 'auto'}}
      >
        <ReactMarkDown children={currProduct?.shortDescription} />
      </motion.div> */}
      <div className="productHeader">
        <span className="prix">{currProduct?.prix}€</span>
        <div className="product-button" id="buy-button"></div>
        <div className="titre-prix-container">
          <h2 className="titre">{currProduct?.displayedTitle}</h2>
        </div>
      </div>

      <div
        className="description"
        dangerouslySetInnerHTML={{__html: currProduct?.description}}
      ></div>
    </Styled.ProductPage>
  )
}

export default ProductPage
