import styled from 'styled-components'
import {motion} from 'framer-motion'
import theme from './theme/theme'

export const ProductPage = styled(motion.div)`
  margin-top: 80vh;
  margin-bottom: 20vh;
  color: ${({background}) => {
    return background === 'graphite' ? 'white' : 'black'
  }};
  width: 85%;
  margin-left: ${theme.marginAround};
  @media screen and (max-width: ${theme.breakpoints.md}px) {
    width: 80%;
  }
  .productDescription {
    position: fixed;
    left: calc(${theme.marginAround} + 10px);
    top: 100px;
    > p {
      font-family: ${theme.fonts.body};
      font-size: 1.1rem;
      margin-top: 0.7rem;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        margin-top: 0.5rem;
      }
    }
  }
  .productHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: ${({background}) => {
      return background === 'graphite' ? '1px solid white' : '1px solid black'
    }};
    padding: 1rem 0;
    margin: 1rem 0;
    .prix {
      font-size: 1.2rem;
      font-style: italic;
      font-family: ${theme.fonts.body};
      margin-bottom: 1rem;
    }
    .titre-prix-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .titre {
        font-size: 1.4rem;
        font-family: ${theme.fonts.body};
      }
    }
    .product-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 12rem;
      height: 4rem;
      margin-bottom: 1rem;
      background-color: ${({background}) => {
        return background === 'graphite' ? 'white' : 'black'
      }};
      color: ${({background}) => {
        return background === 'graphite' ? 'black' : 'white'
      }};
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: invert(100%);
        background-color: transparent;
        border: 1px solid
          ${({background}) => {
            return background === 'graphite' ? 'black' : 'white'
          }};
      }
      * {
        width: 100%;
        height: 100%;
      }
    }
  }
  .description {
    max-width: 700px;
    > p,
    > li {
      font-family: ${theme.fonts.body};
      font-size: 1.2rem;
    }
    li::marker {
      /* transform: scale(0.8); */
    }
  }
`
